@import "styles/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 40px;

  svg {
    transition: opacity 0.1s linear;
  }

  &:hover {
    svg {
      opacity: 1 !important;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    top: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;

    svg {
      opacity: 1;
    }
  }
}
.hiding {
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

  svg {
    opacity: 1 !important;
  }
}
.hide {
  display: none;
}
