.title,
.description {
  color: var(--color-bravo);
}

.title {
  font-size: 20px;
  font-weight: 300;
  font-weight: normal;
  display: block;
  padding-bottom: 17px;
}

.description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  padding-bottom: 25px;
}
