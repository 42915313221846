.enter-animation {
  opacity: 0;
}

.enter-animation-active {
  opacity: 1;
  transition: opacity 0.2s;
}

.exit-animation {
  opacity: 1;
}

.exit-animation-active {
  opacity: 0;
  transition: opacity 0.2s;
}
