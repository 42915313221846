@import "@app/styles/variables.scss";

.root {
  display: flex;
  align-items: center;
}

.before,
.after {
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 14px;
  }
}

.after {
  margin-left: 12px;
}

.before {
  margin-right: 12px;
}
