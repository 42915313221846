@import "styles/variables.scss";

.liked {
  g > g {
    stroke: none;
  }
}

.disabled {
  position: relative;
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.liked-photo {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #0000001f;
  border-radius: 50%;
  transition: 0.3s all;

  &:hover {
    ~ .action-bar {
      .action {
        opacity: 0.6;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 22px;
    height: 22px;
    pointer-events: none;
  }
}

.without-background {
  background-color: transparent;
}

.like {
  width: 18px;
  height: 16px;
  opacity: 0.9;
  transition: 0.2s all;
  @media screen and (max-width: $breakpoint-md) {
    width: 12px;
    height: 11px;
    display: none;
  }
}

.liked-status-wrapper {
  position: absolute;
  right: 21px;
  bottom: 22px;
  z-index: 2;

  @media screen and (max-width: $breakpoint-md) {
    right: 10px;
    bottom: 10px;
    display: none;
  }
}
