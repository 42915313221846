@import "styles/variables";

.scene {
  padding-top: 70px;
  margin-bottom: -18px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 40px;
    margin-bottom: 0;
  }
}

.container {
  @media screen and (max-width: $breakpoint-md) {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.title {
  max-width: 1440px;
  font-family: var(--heading-font-family);
  font-size: 44px;
  font-weight: var(--heading-font-weight);
  line-height: 50px;
  text-align: center;
  overflow-wrap: break-word;
  color: var(--color-bravo);
  margin: 0 auto;

  &.with-photo {
    margin: 0 auto 55px auto;
  }

  &.without-photo {
    margin: 0 auto 22px auto;
  }

  &.with-description {
    margin: 0 auto 20px auto;
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 24px;
    line-height: 31px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    &.with-photo {
      margin: 0 auto 32px auto;
    }

    &.without-photo {
      margin: 0 auto 14px auto;
    }

    &.with-description {
      margin: 0 auto 13px auto;
    }
  }
}
