@import "styles/variables.scss";

.root {
  width: calc(100% + var(--photo-spacing));
  margin-left: calc(var(--photo-spacing) * -1 / 2);
  margin-top: calc(var(--photo-spacing) * -1 / 2);

  @media screen and (max-width: $breakpoint-sm) {
    width: calc(100% + var(--photo-spacing-container-mobile) * -2);
    margin-left: var(--photo-spacing-container-mobile);
  }
}

.masonry-item {
  // 5 колонок от 1920px
  padding: calc(var(--photo-spacing) / 2);
  width: calc(100% / 5);

  @media screen and (max-width: $breakpoint-md) {
    padding: calc(var(--photo-spacing) / 4);
  }

  // 4 колонки до 1920px
  @media screen and (max-width: $breakpoint-xxl) {
    width: calc(100% / 4);
  }

  // 3 колонки до 1280px
  @media screen and (max-width: $breakpoint-xl) {
    width: calc(100% / 3);
  }

  // // 2 колонки до 480px
  @media screen and (max-width: $breakpoint-sm) {
    width: calc(100% / 2); // 100% - gutter / columns
  }
}
