.root {
  position: relative;

  height: 100%;

  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.active {
  opacity: 0.4;
}

.image {
  display: block;

  width: 100%;
  height: 100%;

  opacity: 0;

  transition: opacity 0.22s ease-in;
}

.loaded {
  opacity: 1;
}

.cover {
  object-fit: cover;
}
