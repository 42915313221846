@import "@app/styles/variables.scss";

.root {
  display: flex;
}

.star {
  width: 14px;
  height: 14px;
  overflow: hidden;
  position: relative;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.star-icon {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
  position: absolute;
}
