@import "styles/variables.scss";

.root {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--color-lima);

  &.cursor-pointer {
    cursor: pointer;
  }
}

.hidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
}

.no-touch:hover {
  cursor: default;
}

.overlay {
  &::before {
    opacity: 0.4;
  }
}

.overlay-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

//TODO: Move to actions
.remove {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 1;
  line-height: 10px;
  transition: 0.2s linear;

  &.active {
    display: flex;
  }

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;

    [aria-label][data-tooltip-position] {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    right: 10px;
    bottom: 10px;
  }
}

.modal {
  padding: 32px 40px 40px;
  max-width: 347px;
}
