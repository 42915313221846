@import "styles/variables.scss";

.action-top {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  transition: opacity 0.3s;

  .action {
    padding-top: 12px;
    padding-left: 12px;
    transform: translateY(-8px);
  }
  .visible {
    transform: translateY(0);
    opacity: 1;
  }

  .action-blur {
    svg {
      opacity: 0.6;
    }
  }
}

.action-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  transition: opacity 0.3s;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
    content: "";
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  }

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.action {
  position: relative;
  z-index: 1;
  padding: 16px 10px 0 10px;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(8px);
  will-change: opacity;

  .button {
    margin: 0;
    line-height: 0;
  }

  .share-button {
    margin-bottom: 8px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 22px;
  margin-right: 28px;
  margin-left: auto;

  &.actions-blur {
    .action {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    .action {
      opacity: 0.6;
    }
  }

  &:hover {
    .action:hover {
      opacity: 1;
    }
  }
}

.enter {
  opacity: 0;
  .action-bar {
    opacity: 0;
  }
}

.enter-active {
  opacity: 1;
  transition: opacity 0.3s;
  .action-bar {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.enter-done {
  .action {
    opacity: 1;
    transition-delay: 0.12s;
    transform: translateY(0);
  }
}

.exit {
  opacity: 1;
  .action-bar {
    opacity: 1;
  }
}

.exit-active {
  opacity: 0;
  transition: opacity 0.3s;
  .action-bar {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
