.price {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-black);

  strong {
    font-weight: 400;
  }
}

.currency {
  padding-left: 0.2em;
  font-family: system-ui;
  font-size: inherit;
  line-height: inherit;
  text-rendering: optimizeLegibility;
}

.old-price {
  font-size: inherit;
  position: relative;
  color: var(--color-nickel);
}
