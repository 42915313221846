@import "styles/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-left: 16px;
  cursor: pointer;
  background-color: var(--color-india);
  border-radius: 40px;
  position: relative;
  top: -3px;

  @media screen and (max-width: $breakpoint-md) {
    top: 2px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-left: 12px;
  }
}
.hiding {
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

  @media screen and (max-width: $breakpoint-md) {
    top: 0;
  }
}
