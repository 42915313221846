@import "@app/styles/variables";

.wrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  align-items: flex-end;

  @media only screen and (max-width: $breakpoint-md) {
    top: 8px;
    right: 8px;
  }

  .label {
    z-index: 1;
    padding: 10px 12px 9px;
    color: var(--color-black);
    font-weight: bold;
    font-size: 10px;
    line-height: 2;
    letter-spacing: 0.55px;
    border-radius: 6px;
    background-color: var(--color-white);
    text-transform: uppercase;

    @media only screen and (max-width: $breakpoint-md) {
      font-size: 8px;
      padding: 6px 8px 5px;
    }
  }

  .discount-label {
    padding: 10px 12px;
    color: var(--color-black);
    font-weight: bold;
    font-size: 12px;
    border-radius: 6px;
    background-color: var(--color-white);

    @media only screen and (max-width: $breakpoint-md) {
      font-size: 10px;
      padding: 6px 8px;
    }
  }
}
