@import "styles/variables.scss";

// +Initial state
.action-top {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  transition: opacity 0.3s;

  .action {
    padding-top: 12px;
    padding-left: 12px;
    transform: translateY(-8px);
  }
  .visible {
    transform: translateY(0);
    opacity: 1;
  }

  .action-blur {
    svg {
      opacity: 0.6;
    }
  }
}

.action-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  transition: opacity 0.3s;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
    content: "";
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  }

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.action {
  position: relative;
  z-index: 1;
  padding: 16px 10px 0 10px;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(8px);
  will-change: opacity;

  .button {
    margin: 0;
    line-height: 0;
  }

  .share-button {
    margin-bottom: 8px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 22px;
  margin-right: 28px;
  margin-left: auto;

  &.actions-blur {
    .action {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    .action {
      opacity: 0.6;
    }
  }

  &:hover {
    .action:hover {
      opacity: 1;
    }
  }
}
// -Initial state

// +CSSTransition hooks
.enter {
  opacity: 0;
  .action-bar {
    opacity: 0;
  }
}

.enter-active {
  opacity: 1;
  transition: opacity 0.3s;
  .action-bar {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.enter-done {
  .action {
    opacity: 1;
    transition-delay: 0.12s;
    transform: translateY(0);
  }
}

.exit {
  opacity: 1;
  .action-bar {
    opacity: 1;
  }
}

.exit-active {
  opacity: 0;
  transition: opacity 0.3s;
  .action-bar {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
// -CSSTransition hooks

// +Modal
.modal-title {
  margin-bottom: 15px;

  font-size: 20px;
  font-weight: 300;
  color: var(--color-bravo);
}

.modal-text {
  margin-bottom: 35px;
  font-size: 14px;
  font-weight: 300;
  color: var(--color-charlie);
}

.modal-input {
  margin-bottom: 30px;
}

// -Modal

// +Lists

.lists {
  top: 0;
  right: -20px;
  max-height: 170px;
  transform: translateY(-100%);
}

// -Lists

.active-like {
  position: absolute;
  padding-top: 1px;
  right: 22px;
  bottom: 22px;
  width: 32px;
  height: 32px;
  background: #0000001f;
  border-radius: 50%;
}

.active-like-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  opacity: 0.9;
  transition: 0.2s all;
  transform: translate(-50%, -50%);
}

.liked {
  g > g {
    stroke: none;
  }
}

.remove-progress-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  position: relative;
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.liked-status-wrapper {
  position: absolute;
  right: 21px;
  bottom: 22px;
  z-index: 2;

  @media screen and (max-width: $breakpoint-md) {
    right: 10px;
    bottom: 10px;
    display: none;
  }
}

.liked-photo {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #0000001f;
  border-radius: 50%;
  transition: 0.3s all;

  &:hover {
    ~ .action-bar {
      .action {
        opacity: 0.6;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 22px;
    height: 22px;
    pointer-events: none;
  }
}

.without-background {
  background-color: transparent;
}

.like {
  width: 18px;
  height: 16px;
  opacity: 0.9;
  transition: 0.2s all;

  @media screen and (max-width: $breakpoint-md) {
    width: 12px;
    height: 11px;
    display: none;
  }
}

.like-small {
  width: 14px;
  height: 12px;
}
