.enter-animation {
  height: 0;
}

.enter-animation-active {
  height: 100%;
  transition: height 0.2s;
}

.exit-animation {
  height: 100%;
}

.exit-animation-active {
  height: 0;
  transition: height 0.2s;
}
