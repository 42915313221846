.root {
  font-size: inherit;
  text-decoration: inherit;
  font-weight: 300;
  line-height: 125%;
  position: relative;
  margin-left: 0.3em;

  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    width: 100%;
    height: 1px;
    background: var(--color-nickel);
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }
}
