@import "styles/variables.scss";

.root {
  width: calc(100% + var(--photo-spacing));
  margin-left: calc(var(--photo-spacing) * -1 / 2);
  margin-top: calc(var(--photo-spacing) * -1 / 2);

  @media screen and (max-width: $breakpoint-sm) {
    width: calc(100% + var(--photo-spacing-container-mobile) * -2);
    margin-left: var(--photo-spacing-container-mobile);
  }
}

.item {
  position: absolute;
  padding: calc(var(--photo-spacing) / 2);

  @media screen and (max-width: $breakpoint-md) {
    padding: calc(var(--photo-spacing) / 4);
  }
}
