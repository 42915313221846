@import "@app/styles/variables";

.root {
  position: absolute;

  // width: 100%;
}

.caption {
  font-family: Forum, cursive;
  position: absolute;
  z-index: 2;
  top: 48%;
  transform: translateY(-52%);
  left: 14%;
  max-width: 79%;
  width: 100%;
  padding: 2% 3%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.caption-text {
  color: #111111;
  line-height: 1.05;
  text-transform: uppercase;
  width: 100%;
  min-height: 1em;
  max-height: 2.2em;
  overflow: hidden;
  outline: none;
}

// до 20 символов

.caption-inner-240 {
  &.fixed {
    font-size: 3.69px !important;
  }
}

// от 20

.caption-inner-190 {
  &.fixed {
    font-size: 2.92px !important;
  }
}
