@import "@app/styles/variables";

.root {
  display: flex;
  justify-content: center;
  width: calc(100% + var(--photo-spacing));
  margin-left: calc(var(--photo-spacing) / 2 * -1);

  @media screen and (max-width: $breakpoint-md) {
    padding: 0;

    &.count-3,
    &.count-4,
    &.count-5,
    &.count-6 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: $spec-mobile) {
    &.count-2 {
      display: block;
    }
  }
}

.item {
  flex: 1;
  margin-left: calc(var(--photo-spacing) / 2);
  margin-right: calc(var(--photo-spacing) / 2);
  max-width: 25%;

  @media screen and (max-width: $breakpoint-md) {
    max-width: 50%;
    padding-bottom: 40px;
    margin-left: calc(var(--photo-spacing) / 4);
    margin-right: calc(var(--photo-spacing) / 4);

    .count-3 &,
    .count-4 &,
    .count-5 &,
    .count-6 & {
      max-width: 100%;
    }
  }

  @media screen and (max-width: $spec-mobile) {
    max-width: 100%;
  }
}
